import React from 'react'


function About() {
  return (
    <div>
         <div id='about'>
            <h1><bold>I am Malvin Opondo..</bold></h1>
                <p> I am a FullStack Developer and Creative Designer.I like dabbling in various parts of frontend<br></br> development and like to learn about new tehnologies. 
                During my free time,<br></br> i love to browse for design insprations on Pinterest, Dribble.<br></br>
                I also enjoy travelling and networking.
            </p>
        </div>
    </div>
  )
}

export default About